<template>
  <div class="d-flex flex-column">
    <div class="d-flex my-5">
      <div
        class="
          align-self-center
          text-white
          rounded-circle
          bg-dark-green
          font-rem-1-0
          mx-3
          d-flex
          justify-content-center
          align-items-center
        "
        :style="{
          'min-width': '30px',
          width: '30px',
          'min-height': '30px',
          height: '30px'
        }"
      >
        <span> 9</span>
      </div>
      <div class="brown-green-bold text-left font-rem-0-9 pt-5">
        Cuando hayas finalizado todos estos pasos, da click en el botón de abajo
        en "Registra tu MidBox" para continuar.
      </div>
    </div>

    <button
      class="
        my-3
        py-2
        px-3
        mt-3
        w-75
        h-50
        rounded
        bg-dark-green
        shadow-sm
        pointer
        d-flex
        align-self-center
        justify-content-center
        text-white
        w-fit-content
        z-100
      "
      @click="$emit('nextStep')"
    >
      Registra tu MidBox
    </button>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
